@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-input__prepend-outer {
  margin-top: 0;
  margin-bottom: 0;
}
.v-select {
  display: flex;
  align-items: center;
  // DashboardVue.vue : Design request no border-bottom
  &.dropdown-nav {
    &.v-input.v-text-field {
      .v-input__control {
        .v-input__slot {
          border-radius: var(--border-radius-form-elements);

          &::before,
          &::after {
            display: none;
            border-color: transparent !important;
          }
        }
      }
    }
  }
  &.dropdown-nav {
    .v-input__slot {
      .v-label {
        color: var(--color-text-theme);
        font-weight: 700;
      }
    }

    // Dropdown used as headings (ShiftSchedule.vue)
    &.h2 {
      .v-input__slot {
        .v-label {
          top: 9px;
          font-size: var(--font-size-h2);
          height: 1.7rem;
          line-height: 1.4rem;
        }
      }
    }
  }

  /**
  * Filter styled field
  * Wx Design request no border-bottom
  */
  &.filter-style {
    .v-input__control {
      .v-input__slot {
        border-radius: var(--border-radius-form-elements);

        // hide border-bottom
        &::before,
        &::after {
          display: none;
          border-color: transparent !important;
        }
      }
    }

    // if :dense="false"
    &:not(.v-input--dense) {
      .v-input__control {
        .v-input__append-inner {
          margin-top: 8px;
        }
      }
    }
  }

  /* 
   * Prop `fieldNotDense`
   * This class makes the dropdown the same height as other fields
   * Used in `ui/WxSelectorDialog.vue` for JustificationPanel
   */
  &.field-not-dense {
    &.v-text-field {
      > .v-input__control {
        > .v-input__slot {
          max-height: 41px;
        }
      }
      // label on top
      &--filled {
        .v-label {
          top: 12px;
        }
      }
      // vertically align right chevron icon
      &--enclosed {
        .v-input {
          &__append-inner {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
